import React from "react"
import styled from 'styled-components'

const Heading5 = ({ color, children }) => {
   if(!color) {
       color = "black";  
   }
    return (
        <Heading color={color}>{children}</Heading>
  )
} 

export default Heading5;

const Heading = styled.h5`
    color: ${props => props.color};
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: -0.1rem;
    margin-top: 5px;
    margin-bottom: 5px;
`
