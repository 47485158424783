import styled from 'styled-components'

const Grid3 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;

    @media (max-width: 800px) {
      justify-content: center;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
    }

`;

export default Grid3
