import React from 'react';
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
//import Map from '../components/Map/map';
import styled from 'styled-components'
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionMargin from '../components/Layout/Section/SectionMargin'
import Grid2 from '../components/Layout/Grid2/Grid2'
import GridItem from '../components/Layout/GridItem/GridItem'
import Heading3 from '../components/Type/Heading3'
import Paragraph1 from '../components/Type/Paragraph1'
import SocialLinksMiniBlack from '../components/SocialLinks/SocialLinksMiniBlack'
import ContactForm from '../components/Form/ContactForm'
import Benefits3 from '../components/Benefits3/Benefits3'
import ContactTracker from '../components/ContactTracker/ContactTracker'


const ContactPage = ({ location, data }) => 
  (
        
    <Layout>
        <SEO title="Summon Contact"
            description="Interested to know more about Summon, how it works or who it's for? 
            Please drop us a line."
        location={location} />

        <PageHeader title="Contact us" 
            text="Interested to know more about Summon, how it works or who it's for? 
                Please either complete the form below or sent us an email."/>

        <Benefits3 />

        <SectionMargin bgColor="#f8f8f9" marginBottom="0px">
            <Grid2>
                <GridItem order="1">
                    <Heading3 >Get in touch</Heading3>
                    <ContactForm formAction="contact" />
                </GridItem>
                <GridItem order="2" >
                    <Heading3 >Contact Details</Heading3>
                    <Paragraph1>
                        Summon currently operates in Melbourne, Sydney, Gold Coast and Brisbane
                    </Paragraph1>
                    <table>
                        <tbody>
                            <tr>
                                <TH>Email</TH>
                                <td>
                                    <ContactTracker
                                        trackAction="Contact"
                                        trackLabel="Email Contact"
                                        hrefType="mailto:"
                                        hrefTo="hello@summon.com.au" >
                                        hello@summon.com.au
                                    </ContactTracker>
                                </td>
                            </tr>
                            <tr>
                                <TH>Phone</TH>
                                <td>
                                    <ContactTracker
                                        trackAction="Contact"
                                        trackLabel="Phone Contact"
                                        hrefType="tel:"
                                        hrefTo="0492786666" >
                                            0492 SUMMON
                                    </ContactTracker>
                                </td>
                            </tr>
                            <tr>
                                <TH>Social</TH>
                                <td><SocialLinksMiniBlack/></td>
                            </tr>
                        </tbody>
                        
                    </table>

                    {/* <Paragraph1>
                        <strong>Address</strong> (Melbourne) <br/>
                        787 High St, Armadale VIC 3143
                    </Paragraph1>
                    <MapContainer>
                        <Map />
                    </MapContainer> */}

                </GridItem>
            </Grid2>
        </SectionMargin>

    </Layout>
)

export default ContactPage;

// const MapContainer = styled.div`
//     height: 300px;
//     width: 300px;
//     margin-bottom: 20px;
// `

const TH = styled.th`
    width: 60px;
    padding: 10px 0px 10px 0px;
`



