import React from "react"
import styled from 'styled-components'

const Paragraph2 = ({ color, align, children }) => {
   if(!color) color = "black"; 
   if(!align) align = "left"; 
   
    return (
        <Paragraph color={color} align={align}>{children}</Paragraph>
  )
} 

export default Paragraph2;

const Paragraph = styled.p`
    color: ${props => props.color};
    text-align: ${props => props.align};
    font-size: .8rem;
    line-height: 1.1rem;
    width: 250px;
    margin-top: 0px;
`
