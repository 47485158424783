import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import SectionPageMargin from '../Layout/Section/SectionPageMargin'
import Grid3 from '../Layout/Grid3/Grid3'
import GridItem from '../Layout/GridItem/GridItem'
import Heading5 from '../Type/Heading5'
import Paragraph2 from '../Type/Paragraph2'
import FlexCenter from '../Layout/Flex/FlexCenter'
import ImageBox from '../Layout/ImageBox/ImageBox'
import Img from "gatsby-image"

const Benefits3 = () => {
    const data = useStaticQuery(graphql`
        query {
        
            firstImage: file(relativePath: { eq: "contact-img-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 106) {
                ...GatsbyImageSharpFluid
                }
             }
            }
        
            secondImage: file(relativePath: { eq: "contact-img-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 106) {
                ...GatsbyImageSharpFluid
                }
             }
            }
        
            thirdImage: file(relativePath: { eq: "contact-img-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 106) {
                ...GatsbyImageSharpFluid
                }
             }
            }
        }

    `)

    return (
        <SectionPageMargin marginBottom="95px">
        <Grid3>
            <GridItem>
                <FlexCenter>
                    <ImageBox size="106px" marginTop="0px">
                        <Img fluid={data.firstImage.childImageSharp.fluid} />
                    </ImageBox>
                    <Heading5>Free valuation</Heading5>
                    <Paragraph2 align="center">
                        We will be in touch with you to learn more about your car, and provide you with a free valuation.
                    </Paragraph2>
                </FlexCenter>
            </GridItem>

            <GridItem>
                <FlexCenter>
                    <ImageBox size="106px" marginTop="0px">
                        <Img fluid={data.secondImage.childImageSharp.fluid} />
                    </ImageBox>
                    <Heading5>Hassle free selling</Heading5>
                    <Paragraph2 align="center">
                        If you decide to sell with Summon, we take care of all of the hassles. You can sit back and relax.
                    </Paragraph2>
                </FlexCenter>
            </GridItem>

            <GridItem>
                <FlexCenter>
                    <ImageBox size="106px" marginTop="0px">
                        <Img fluid={data.thirdImage.childImageSharp.fluid} />
                    </ImageBox>
                    <Heading5>Get a private sale price</Heading5>
                    <Paragraph2 align="center">
                        We don’t have the overheads of a traditional car dealership, and pass these cost savings onto you.
                    </Paragraph2>
                </FlexCenter>
            </GridItem>
        </Grid3>
        </SectionPageMargin>
    );
}

export default Benefits3;
